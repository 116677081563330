<template>

    <div>

        <div class="breadcrumb justify-content-end">
            <div class="d-flex  breadcrumb-wrapper pr-1">
                <b-breadcrumb-item :to="{ name: 'dashboard' }">
                    <feather-icon class="align-text-top" icon="HomeIcon" size="15"/>
                </b-breadcrumb-item>
                <b-breadcrumb-item active>{{$t('label_sms_patterns')}}</b-breadcrumb-item>
            </div>
        </div>

        <b-card no-body>

            <b-card-body>
                <b-row>

                    <b-col cols="12">
                        <b-row class="">

                            <b-col cols="12" md="3" class="mb-1">
                                <label>{{$t('label_category')}}</label>

                                <infinity-scroll
                                        v-model="filterData.category_id"
                                        selected-prop="id"
                                        label="idCountry"
                                        :url="'/'+ MODULE_PREFIX +'_categories'"
                                        :placeholder="$t('label_select')"
                                >
                                    <template #label="{item}">{{item.name}}</template>

                                </infinity-scroll>
                            </b-col>

                        </b-row>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>

        <b-card
                no-body
                class="mb-0"
        >

            <div class="m-2">

                <b-row>

                    <b-col
                            cols="12"
                            md="5"
                            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"

                    >
                        <!--<label>{{ $t('label_show') }}</label>-->
                        <v-select
                                v-model="tableData.perPage"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="tableData.perPageOptions"
                                :clearable="false"
                                :searchable="false"
                                class="per-page-selector d-inline-block mr-1"
                        >
                            <template v-slot:no-options="option">
                                {{$t('label_no_items')}}
                            </template>
                        </v-select>

                        <column-chooser v-model="selectedColumns" :not-touch="['id']"
                                        :columns="columnsDefs"></column-chooser>

                    </b-col>


                    <!-- Search -->
                    <b-col
                            cols="12"
                            md="5"
                            class="ml-auto d-flex align-items-center justify-content-start mb-1 mb-md-0"
                    >

                        <b-form-input
                                v-model="tableData.searchQuery"
                                type="search"
                                debounce="500"
                                class="d-inline-block mr-1 "
                                :placeholder="$t('label_search')+'...'"
                        />

                        <b-button
                                v-if="$can('edit', 'pattern.tab_details')"
                                :to="{name: MODULE_PREFIX + '_add'}"
                                variant="primary"

                        >
                            <span class="text-nowrap">{{ $t('label_add') }}</span>
                        </b-button>
                    </b-col>
                </b-row>

            </div>

            <!--***********************************************************TABLE*********************************************-->

            <div class="px-2 table-container-wrap">
                <!--sticky-header-->
                <b-table

                        :ref="MODULE_PREFIX+'_TABLE'"
                        class="position-relative transited-table"
                        :items="tableItems"
                        responsive
                        no-border-collapse
                        :busy="isBusy"
                        :fields="selectedColumns"
                        primary-key="id"
                        :sort-by.sync="tableData.sortBy"
                        no-local-sorting
                        :sort-desc.sync="tableData.sortDesc"
                        show-empty
                        :tbody-transition-props="{ name: 'flip-list'}"
                        :empty-text="$t('label_no_table_data')"
                >

                    <template #head()="data">
                        <span class="text-secondary">{{ $t(data.label).ucFirst() }}</span>
                    </template>

                    <!--<template #cell(id_pattern)="data">-->
                        <!--{{'#' + data.item.id_pattern}}-->
                    <!--</template>-->
                    <template #cell(category)="data">
                        {{data.item.category? data.item.category.name : ''}}
                    </template>

                    <!--<template #cell(download)="data">-->
                     <!--<div class="d-flex">-->
                         <!--<a :href="$base_url + MODULE_PREFIX+'/download/'+data.item.id_pattern+'?type=pdf'">-->
                             <!--<b-img :src="require('@/assets/images/icons/pdf.png')" height="20" width="auto" class="mr-1"/>-->
                         <!--</a>-->
                         <!--<a :href="$base_url + MODULE_PREFIX+'/download/'+data.item.id_pattern+'?type=doc'">-->
                             <!--<b-img :src="require('@/assets/images/icons/doc.png')" height="20" width="auto" class="mr-1"/>-->
                         <!--</a>-->
                         <!--<a :href="$base_url + MODULE_PREFIX+'/download/'+data.item.id_pattern+'?type=dochtml'">-->
                             <!--<feather-icon icon="FileIcon" size="20"></feather-icon>-->
                             <!--&lt;!&ndash;<b-img :src="require('@/assets/images/icons/pdf.png')" height="20" width="auto" />&ndash;&gt;-->
                         <!--</a>-->
                     <!--</div>-->
                    <!--</template>-->


                    <template #cell(actions)="data">

                        <b-dropdown
                                dropleft
                                boundary="window"
                                variant="link"
                                no-caret
                                @show="$event.vueTarget.$refs['toggle'].closest('tr').classList.add('row-selected')"
                                @hide="$event.vueTarget.$refs['toggle'].closest('tr').classList.remove('row-selected')"
                                :right="$store.state.appConfig.isRTL"
                        >

                            <template #button-content>
                                <feather-icon
                                        icon="MoreVerticalIcon"
                                        size="16"
                                        class="align-middle text-body"
                                />
                            </template>


                            <b-dropdown-item
                                    :to="{name:MODULE_PREFIX+'_edit', params:{id:data.item.id}}">
                                <feather-icon icon="EditIcon"/>
                                <span class="align-middle ml-50">{{ $t('label_edit') }}</span>
                            </b-dropdown-item>

                            <b-dropdown-item @click="deleteItem(data.item.id)">
                                <feather-icon icon="TrashIcon"/>
                                <span class="align-middle ml-50">{{ $t('label_delete') }}</span>
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>

                </b-table>
                <div class="mx-2 mb-2">
                    <b-row>

                        <b-col
                                cols="12"
                                sm="6"
                                class="d-flex align-items-center justify-content-center justify-content-sm-start"
                        >
                            <span class="text-muted">{{ $t('label_showed') + ' '+ $t('label_from').toLowerCase() + ' ' + dataMeta.from + ' ' + $t('label_to_date') + ' ' + dataMeta.to + ' ' + $t('label_of') + ' ' + dataMeta.of }} </span>
                        </b-col>
                        <!-- Pagination -->
                        <b-col
                                cols="12"
                                sm="6"
                                class="d-flex align-items-center justify-content-center justify-content-sm-end"
                        >

                            <b-pagination
                                    v-model="tableData.currentPage"
                                    :total-rows="tableTotal"
                                    :per-page="tableData.perPage"
                                    first-number
                                    last-number
                                    class="mb-0 mt-1 mt-sm-0"
                                    prev-class="prev-item"
                                    next-class="next-item"
                            >
                                <template #prev-text>
                                    <feather-icon
                                            icon="ChevronLeftIcon"
                                            size="18"
                                    />
                                </template>
                                <template #next-text>
                                    <feather-icon
                                            icon="ChevronRightIcon"
                                            size="18"
                                    />
                                </template>
                            </b-pagination>

                        </b-col>

                    </b-row>
                </div>
            </div>

        </b-card>



    </div>
</template>

<script>

    import {
        BCard, BButton, BTable, BMedia, BAvatar, BLink, BFormInput, BImg,
        BBadge, BDropdown, BDropdownItem, BPagination, BCardBody, BCardHeader, BFormCheckbox, BTooltip
    } from 'bootstrap-vue'

    import vSelect from 'vue-select'
    import columnChooser from '@/views/components/columnChooser'
    import infinityScroll from '@/views/components/infinityScroll'

    import {MODULE_PREFIX} from './moduleHelper'

    export default {
        components: {
            BCard,
            BCardBody,
            BButton,
            BTable,
            BMedia,
            BAvatar,
            BLink,
            BBadge,
            BDropdown,
            BDropdownItem,
            BPagination,
            BFormCheckbox,
            BCardHeader,
            BTooltip,
            BFormInput,
            columnChooser,
            vSelect,
            infinityScroll,BImg,

        },
        props: ['tableType'],
        data() {
            return {
                // PREFIX,
                MODULE_PREFIX,


                editedItem: false,
                selectedColumns: [],

                columnsDefs: [
                    // {label: '#', key: 'id_pattern'},
                    {label: 'label_category', key: 'category', sortable: false},
                    {label: 'label_pattern_name', key: 'pattern_name', sortable: false},
                    {label: 'label_content', key: 'pattern_content', sortable: false},

                    {label: 'label_action', key: 'actions'}
                ],
                isBusy: false,
                tableTotal: 0,
                tableItems: [],

                tableData: {
                    currentPage: 1,
                    perPage: 100,
                    searchQuery: '',
                    sortBy: 'id',
                    sortDesc: true,
                    perPageOptions: [10, 25, 50, 100],
                },
                filterData: {
                    category_id: '',
                },

            }
        },

        watch: {
            tableData: {
                handler: function (newVal, old) {
                    let self = this;
                    self.refreshTableId = setTimeout(function(){
                        clearInterval(self.refreshTableId);
                        self.refreshDataTable();
                    },100);
                },
                deep: true
            },
            filterData: {
                handler: function (old, newVal) {
                    this.refreshDataTable();
                },
                deep: true
            }
        },

        created() {

            this.columnsDefs = this.columnsDefs.filter((el) => {
                if (el.key == 'actions' && !this.$can('edit', 'pattern.tab_details')) {
                    return false;
                }
                return true;
            });

            this.refreshDataTable();
        },
        computed: {
            dataMeta: (context) => {
                return {
                    from: context.tableData.perPage * (context.tableData.currentPage - 1) + (context.tableItems.length ? 1 : 0),
                    to: context.tableData.perPage * (context.tableData.currentPage - 1) + context.tableItems.length,
                    of: context.tableTotal,
                }
            },
        },

        methods: {

            refreshDataTable: function () {
                this.isBusy = true;
                this.async('get', this.MODULE_PREFIX + 's', {
                    params: {
                        // [this.MODULE_PREFIX+ '_id']: this.moduleItem.id,
                        length: this.tableData.perPage,
                        start: (this.tableData.currentPage - 1) * this.tableData.perPage,
                        search: this.tableData.searchQuery,
                        sort_by: this.tableData.sortBy,
                        sort_desc: this.tableData.sortDesc? true : null,

                        category_id: this.filterData.category_id,

                    }
                }, function (resp) {

                    this.tableItems = resp.data.items;


                    this.tableTotal = resp.data.items.length;

                    this.isBusy = false;
                });

            },
            deleteItem(id) {
                this.confirmDeleting((result) => {

                        this.async('delete', '/' + this.MODULE_PREFIX + 's/' + id, {}, function (resp) {
                            this.refreshDataTable();
                        });

                })
            },

        },

    }
</script>

